<template>
  <div class="tuto"></div>
</template>

<script>
import { textContent } from '@/data/enigme2'

export default {
  name: 'Enigme2MainScreenTuto',
  data() {
    return {
      textContent: textContent
    }
  }
}
</script>
<style lang="scss" scoped></style>
